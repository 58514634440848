import axios, { CancelToken } from 'axios';

export async function isValidAddress(address, countryCode) {
  // console.log('isValidAddress', address, countryCode);
  const result = await geocode(address, countryCode);
  // console.log('result: ', result);
  return result.status === 'OK' && result.results.length > 0;
}

/**
 * `NI` is not a real country code that google understands, instead swap it with `GP`.
 */
export function convertCountryCodeForMaps(countryCode) {
  // console.log('countryCode', countryCode);
  if (Array.isArray(countryCode) && countryCode[0]) {
    countryCode = countryCode[0];
  }
  if (countryCode.toLowerCase) {
    countryCode = countryCode.toLowerCase();
  }

  // console.log('convertCountryCodeForMaps', countryCode);
  // if (countryCode === 'eu') {
  //   return 'de';
  // }
  return countryCode === 'ni' ? 'gb' : countryCode;
}

const europeBounds = {
  north: 64.83139,
  south: 30.44903,
  east: 44.90139,
  west: -13.71109
};

export function geocode(address, countryCode) {
  // console.log('geocode params: ', params);
  const source = CancelToken.source();
  const params = {
    address,
    key: process.env.GOOGLE_MAPS_API_KEY,
    type: '(regions)'
  };

  if (countryCode) {
    if (convertCountryCodeForMaps(countryCode) === 'eu') {
      params.bounds = `${europeBounds.south},${europeBounds.west}%7C${europeBounds.north},${europeBounds.east}`;
    } else {
      params.components = 'country:' + countryCode;
    }
  }

  // console.log('source: ', source);
  const request = axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
    cancelToken: source.token,
    params
  });

  const promise = request.then((response) => response.data);

  promise.cancel = () => {
    console.log('canceled');
    source.cancel('Operation canceled by the user.');
  };

  return promise;
}
