<template>
  <div class="component-hero-home">
    <b-container class="component-hero-home__title">
      <h1 v-if="user">Welcome home <br />{{ user.name.first }}</h1>
      <h1 v-else>
        Join a startup
        <!-- Find the most
        <br />exciting startup jobs -->
        <span>Discover {{ jobsTotal }} open positions</span>
      </h1>
    </b-container>
    <div class="component-hero-home__image-search">
      <b-container>
        <!-- <div class="image-container">
          <img src="~/assets/components/page-home/pattern.svg" class="pattern" />
        </div> -->

        <div class="component-hero-home__search-job-wrapper">
          <SearchBar
            v-model="searchForm"
            big-size
            search-placeholder="Job title or keyword"
            location-placeholder="City, area or select remote"
            include-remote-in-country-selector
            :on-submit="onSearch"
          />
        </div>
      </b-container>

      <div class="component-hero-home__image-wrapper">
        <StartupCarousel :prefetchedStartup="prefetchedStartup" />
      </div>
    </div>

    <!-- <Panel is-homepage :container="!!user" /> -->
  </div>
</template>

<script>
import SearchBar from '@/components/search/SearchBar';
// import Panel from '@/components/LogoPanel'
import StartupCarousel from './StartupCarousel';

export default {
  name: 'HeroHome',
  components: {
    SearchBar,
    // Panel,
    StartupCarousel
  },
  props: {
    user: Object,
    prefetchedStartup: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      searchForm: {
        search: undefined,
        location: undefined,
        countryCode: this.$store.getters['meta/sitePreferredCountryCode']
      },
      jobsTotal: undefined
    };
  },
  async created() {
    const query = {
      //approved: true
    };

    try {
      const { data } = await this.$axios.get('/jobs/count', {
        params: { ...query }
      });
      this.jobsTotal = data.count;
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    onSearch() {
      this.$store.dispatch('jobSearch/add', {
        params: this.searchForm,
        createIfNotExists: true
      });

      setTimeout(() => {
        this.$router.push({
          path: '/jobs',
          query: {
            search: this.searchForm.search,
            location: this.searchForm.location,
            countryCode: this.searchForm.countryCode
          }
        });
      }, 250);
    }
  }
};
</script>

<style scoped lang="scss">
.component-hero-home {
  width: 100wv;

  overflow: hidden;
  // margin-top: rem(80px);
  margin-top: 2rem;

  @include media-breakpoint-down(lg) {
    margin-top: rem(60px);
  }

  @include media-breakpoint-down(sm) {
    margin-top: rem(50px);
  }

  &__title {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    h1 {
      flex: 0 0 60%;

      @include media-breakpoint-down(lg) {
        flex: 0 0 74%;
      }

      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
        font-size: rem(36px);
        // margin-bottom: rem(20px);
      }

      span {
        margin: 0;
        font-weight: 700;
        font-size: rem(32px);
        display: block;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: normal;
        color: $gray-700;
      }
    }

    p {
      font-size: rem(20px);
      line-height: 1.6;

      @include media-breakpoint-down(lg) {
        font-size: rem(17px);
      }

      a {
        color: $blue-900;
      }
    }
  }

  &__image-search {
    position: relative;
    overflow: hidden;
    // padding-top: rem(80px);
    padding-top: 2rem;

    @include media-breakpoint-down(lg) {
      // padding-top: rem(60px);
    }

    @include media-breakpoint-down(sm) {
      // padding-top: rem(50px);
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 50%;
      background-color: $gray-100;
    }

    .container {
      position: relative;
      z-index: 2;
    }
  }

  &__pattern {
    position: absolute;
    z-index: 2;

    top: 0;
    left: 0;

    @include media-breakpoint-only(lg) {
      width: rem(380px);
    }

    @include media-breakpoint-only(md) {
      width: rem(280px);
    }

    @include media-breakpoint-down(sm) {
      width: rem(230px);
    }
  }

  &__search-job-wrapper {
    position: relative;
    z-index: 3;
    padding: rem(20px) 0;

    .component-searchjob {
      @include media-breakpoint-down(sm) {
        padding-top: rem(10px);
        box-shadow: $box-shadow-dark;
      }
    }
  }

  &__image-wrapper {
    position: relative;
    padding: 0 rem(40px);
    margin: rem(-75px) rem(-70px) 0 rem(-70px);
    text-align: center;
    z-index: 1;

    &.image-inner {
      padding: 0;
    }

    @include media-breakpoint-only(lg) {
      padding: 0 rem(20px);
    }

    @include media-breakpoint-down(md) {
      padding: 0;
    }

    @include media-breakpoint-down(sm) {
      img {
        height: rem(250px);
        width: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }

    picture,
    img {
      width: auto;
      max-width: 100%;
    }
  }
}

.image-container {
  width: 300px; /* Ширина контейнера */
  height: 300px; /* Высота контейнера */
  overflow: hidden; /* Обрезать все, что выходит за границы контейнера */

  position: absolute;
  z-index: 2;

  top: 0;
  left: 0;

  @include media-breakpoint-only(lg) {
    width: rem(380px);
    height: rem(300px);
  }

  @include media-breakpoint-only(md) {
    width: rem(280px);
    height: rem(300px);
  }

  @include media-breakpoint-down(sm) {
    width: rem(230px);
    height: rem(300px);
  }
}

.image-container img {
  width: 100%; /* Сделать изображение 100% ширины контейнера */
  height: auto; /* Сохранить пропорции */
}
</style>
