import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

// http://www.ericfeminella.com/blog/2012/08/18/determining-if-an-object-is-empty-with-underscore-lo-dash/
export function isEmptyDeep(value) {
  if (value === null || value === undefined) {
    return true;
  }

  if (isObject(value)) {
    return !some(value, (value) => !isEmptyDeep(value));
  }

  if (isArray(value)) {
    return isEmpty(value);
  }

  if (typeof value === 'string') {
    return !value;
  }

  return false;
}

export function applyDrag(arr, dragResult) {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
}

export const customCountrySort = (a, b) => {
  if (a.value === 'REMOTE') {
    return 1;
  }
  if (b.value === 'REMOTE') {
    return -1;
  }
  if (a.value === 'EU') {
    return b.value === 'REMOTE' ? -1 : 1;
  }
  if (b.value === 'EU') {
    return a.value === 'REMOTE' ? 1 : -1;
  }
  return a.value > b.value ? 1 : a.value === b.value ? 0 : -1;
};
