<template>
  <div>
    <gmap-autocomplete
      ref="myInput"
      :key="fieldKey"
      v-model="inputValue"
      v-bind="$attrs"
      :options="options"
      autocomplete="off"
      :class="inputClass"
      @input="onInput"
      @change="onChange"
      @place_changed="onPlaceChanged"
      @blur="onBlur"
      @keydown.enter="onKeyDownEnter"
    />

    <slot />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: String
    },
    type: {
      type: String,
      validator(value) {
        return ['(regions)', '(cities)', 'address'].includes(value);
      }
    },
    // https://developers.google.com/places/supported_types
    componentRestrictions: Object,
    inputClass: {
      type: [String, Array],
      default: 'form-control'
    }
  },
  data() {
    return {
      inputValue: this.value
    };
  },
  computed: {
    /**
     * @workaround: changing options does not update the autocomplete, instead we force a new element when options change.
     */
    fieldKey() {
      return JSON.stringify(this.options);
    },
    options() {
      // console.log('this.componentRestrictions', this.componentRestrictions);
      // EUROPE BOUNDS
      // 64.83139, -13.71109
      // 64.26398, 37.30106
      // 30.66964, -22.95106
      // 30.44903, 44.90139
      const europeBounds = {
        north: 64.83139,
        south: 30.44903,
        east: 44.90139,
        west: -13.71109
      };
      if (
        this.componentRestrictions &&
        this.componentRestrictions.country &&
        this.componentRestrictions.country[0] &&
        this.componentRestrictions.country[0] === 'eu'
      ) {
        return {
          bounds: europeBounds,
          strictBounds: true,
          types: ['(cities)'],
          fields: ['name', 'geometry.location', 'place_id', 'formatted_address']
        };
      } else {
        return {
          types: this.type ? [this.type] : [],
          componentRestrictions: this.componentRestrictions,
          fields: ['name', 'geometry.location', 'place_id', 'formatted_address']
        };
      }
    }
  },
  watch: {
    value(newValue) {
      this.inputValue = newValue;
    },
    inputValue(newValue) {
      this.$emit('input', newValue);
    }
  },
  methods: {
    onInput(evt) {
      // console.log('onInput', evt);
      this.inputValue = evt.target.value;
    },
    onChange(evt) {
      // console.log('onChange', evt);
      this.inputValue = evt.target.value;

      this.$emit('change', this.inputValue);
    },
    onBlur() {
      if (!this.$refs.myInput) {
        // unmounted.
        return;
      }

      this.inputValue = this.$refs.myInput.$el.value;

      /**
       * when using mouse to select a option from the dropdown, for some reason the `value` is delayed abit.
       */
      setTimeout(() => {
        // console.log(this.$refs);
        this.inputValue = this.$refs.myInput.$el.value;
        this.$emit('blur', this.inputValue);
      }, 50);
    },
    onKeyDownEnter() {
      this.inputValue = this.$refs.myInput.$el.value;
    },
    onPlaceChanged(place) {
      // console.log('onPlaceChanged', place, this.$refs);
      this.inputValue = this.$refs.myInput.$el.value;

      this.$emit('place_changed', place);
    }
  }
};
</script>

<style>
.pac-container {
  z-index: 1000000;
}
</style>
